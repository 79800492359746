.card {
  background-color: rgba(28, 28, 28, 0.6);
  min-width: 100px;
  min-height: 100px;
  padding: 40px 30px;
  display: table;
  text-align: center;
  margin: auto; }
  .card .image {
    border-radius: 100%;
    margin: auto;
    width: 125px;
    height: 125px;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100%; }
  .card .title {
    text-align: center;
    font-size: 1.8rem;
    font-weight: 400;
    letter-spacing: .2rem;
    margin: 10px auto 0px; }
  .card .sub-title {
    text-align: center;
    margin-bottom: 5px;
    font-size: 1.2rem;
    letter-spacing: .1rem;
    font-weight: 200; }
