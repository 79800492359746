body {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5; }

html {
  box-sizing: border-box;
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

a, a:hover {
  text-decoration: none; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.view {
  transform: translate3d(0, 0, 0);
  animation: fadeIn 300ms linear; }

.project-box {
  animation: fadeIn 500ms linear; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-o-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }


/* .outfit-font {
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
} */

/* h1,h2,h3,h4,h5 {
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
  font-style: normal; 
} */
