.link-button {
  transition: 400ms;
  border-radius: 5px;
  border: 1px solid #989da1;
  padding: 5px 7px;
  width: 90px;
  margin: 3px 5px;
  text-decoration: none;
  cursor: pointer;
  text-align: center; }
  .link-button:hover {
    border-color: #05fbff; }
    .link-button:hover .link-text {
      color: #05fbff; }
  .link-button .link-text {
    transition: 800ms;
    color: #989da1;
    z-index: 2; }
