.view-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  font-family: "Barlow Semi Condensed", sans-serif;
  color: #989DA1; }

p, h1, h2, h3, h4, h5, h6 {
  margin: 0; }
