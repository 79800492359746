.navbar {
  text-align: center;
  margin: auto auto 30px auto; 
}

.titleWrapper {
  margin-bottom: 15px;
}

.title {
  line-height: 4rem;
  font-size: 4.2rem;
  margin: 0;
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
  // font-weight: 800;
  font-style: normal; 
}

.links {
  align-items: center;
  display: flex;
  justify-content: center;
}

.link {
  align-items: center;
  display: flex;
  justify-content: center;
}

.link:not(:last-child):after {
  content: "";
  background-color: black;
  border-radius: 100%;
  display: inline-block;
  height: .3rem;
  margin: 0 15px;
  width: .3rem;
}
