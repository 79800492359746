@import '../../mixins.scss';

.cardWrapper {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
  column-gap: 20px;

  @include medium-devices {
    grid-template-columns: 1fr 1fr;
  }

  @include large-devices {
    // grid-template-columns: 1fr 1fr 1fr;
  }
}
